<template>
    <div>
        <div class="main" v-if="chat_list.length != 0">
            <div class="main_body" >
                <div class="chat_list">

                    <div class="list-box" v-for="(item,index) in chat_list" :key='index' @click="change_major(item)">
                        <div class="avatar">
                            <img :src="item.message.imgurl" mode="">
                            <div class="num" v-if="item.message.status">
                                1
                            </div>
                        </div>
                        <div class="detail">
                            <div class="detail-name">
                                <b>{{item.message.nickname}} {{item.message.typesName}}</b> 
                            </div>
                            <div class="detail-message">
                               {{item.message.message}}
                            </div>
                        </div>
                        <div class="list-time">
                            {{item.message.ctime}}
                        </div>
                    </div>

                </div>

                <div class="chat_box" v-show="show_chat">

                    <div class="head">
                        <img :src="major.imgurl" alt="">
                        <div class="major_name">
                            <div>
                                {{major.nickname}} {{major.typesName}}                                
                            </div>
                            <!-- <div>
                                从业十五年，中国全国律师协会会员律师
                            </div> -->
                        </div>
                    </div>

                    <div class="chat_body">
                         <div class="right_chat" v-for="(item,index) in chat" :key="index">
                            <div class="chat_time">
                                {{item.ctime}}
                            </div>
                            <div class="left_main" v-if="item.types == 1">
                                <img :src="item.avatar" mode="">
                                <div class="chat_text">
                                    {{item.message}}
                                </div>
                            </div>
                            <div class="right_main" v-if="item.types ==0">
                                <div class="chat_text">
                                    {{item.message}}
                                </div>
                            <img :src="item.avatar" mode="">
                                
                            </div>
                        </div>
                    </div>

                    <div class="enter_box">
                        <div class="enter_body">
                            <img src="../assets/chat.png" alt="">
                            <input type="text" v-model="send_message" placeholder="请输入文字内容">
                        </div>
                        <div class="send" @click="sendMessage">
                            发送
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="small_main" v-else>
                暂无消息
            </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            order_id:'',
            chat_list:'',
            major:'',
            chat:'',
            show_chat:false,
            send_message:''
        }
    },
    created(){
        // if(this.$route.query.order_id){
        //     console.log(this.$route.query.order_id)
        //      this.load_chat()
        // }
       this.loadList()
        
    },
    methods:{
        load_chat(){
            this.$api.userpost('user.BoardDetail',{order_id:this.order_id},(res)=>{
                if(res.data.status){
						this.chat = res.data.data
					}else{
						this.$message.error(res.data.msg)
				}
            })
        },
        loadList(){
            this.$api.userpost('user.getBoardlist',{page:1},(res)=>{
					if(res.data.status){
                        this.chat_list = res.data.data
					}else{
						this.$common.toast(res.data.msg)
					}
			})
        },
        change_major(item){
            this.major = item.message
            this.order_id = item.order_id
            this.load_chat()
            this.show_chat = true
        },
        sendMessage(){
            this.$api.userpost('user.BoardAdd',{order_id:this.order_id,message:this.send_message},(res)=>{
					this.$message({
                       message: res.data.msg,
                       type:'success'
                    })
					if(res.data.status){
						this.send_message = ''
						this.load_chat()
					}
				})
        }
    }
}
</script>
<style lang="scss" scoped>
.small_main{
    padding: 30px;
        margin: 40px auto;
        width: 1200px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
}
.enter_box{
    border-top:1px solid #E1ECFE;;
    width: 654px;
    height: 86px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    .enter_body{
        width: 474px;
        display: flex;
        align-items: center;
        img{
            height: 32px;
            width: 32px;
            margin-left: 23px;
        }
        input{
            border: none;
            font-size: 22px;
            width: 300px;
            margin-left: 13px;
            outline-style: none;
        }
    }
    
    .send{
        width: 180px;
        height: 86px;
        background: #144591;
        opacity: 1;
        border-radius: 0px 0px 10px 0px;
        color:#FFF;
        line-height: 86px;
        text-align: center;
        font-size: 22px ;
    }
}
.right_chat{
		margin-top: 20px;
		.chat_time{
			text-align: center;
			font-size: 25upx;
			color: #999999;
		}
		.left_main{
			display: flex;
			margin-top: 10px;
			img{
				margin-left: 24px;
				margin-right: 16px;
				margin-top: 16px;
				height: 53px;
				width: 53px;
				border-radius: 50%;
			}
			.chat_text{
                text-align: left;
                padding: 17px;
				width: 416px;
                background: #F1F4F8;
                opacity: 1;
                border-radius: 10px;
                font-size:18px;
                color:#595959
			}
		}
		.right_main{
			display: flex;
			justify-content: flex-end;
			margin-top: 10px;
			img{
			    margin-left: 16px;
				margin-right: 24px;
				margin-top: 16px;
				height: 53px;
				width: 53px;
				border-radius: 50%;
			}
			.chat_text{
                text-align: left;
				padding: 17px;
				width: 416px;
                background: #F1F4F8;
                opacity: 1;
                border-radius: 10px;
                font-size:18px;
                color:#595959
			}
		}
	}

.chat_body{
    height: 685px;
    padding-bottom: 20px;
    overflow: auto;
}
.major_name{
    height: 102px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    div:nth-child(1){
        font-weight: bold;
        font-size: 18px;
    }
    div:nth-child(2){
        font-size: 14px;
        width: 360px;
        overflow: hidden;
        color: #666;
    }
}
.head{
    display: flex;
    height: 102px;
    border-bottom: 1px #D7E6FE solid;
    img{
        height: 76px;
        width: 76px;
        margin: 16px 20px 0 24px;
    }
}

.main{
        padding: 30px;
        margin: 40px auto;
        width: 1200px;
        height: 950px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }
.main_body{
    display: flex;
}
.chat_list{
    width: 455px;
    height: 894px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.chat_box{
    margin-left: 30px;
    width: 655px;
    height:894px;
    background: #fff;
    border-radius: 10px;
}


.list-box{
    margin-top: 30px;
		width: 455px;
		background-color: #FFF;
		border-radius: 10px;
		height: 78px;
		display: flex;
		align-items: center;
	}
	
	.avatar{
		margin-left: 20px;
		position: relative;
		height: 56px;
		width: 56px;
	}
	
	.avatar img{
		height: 56px;
		width: 56px;
		border-radius: 50%;
	}
	
	.num{
		position: absolute;
		right: -10px;
		top: -10px;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background-color: #EF4848;
		text-align: center;
		line-height: 20px;
		color: #FFF;
		font-size: 17px;
	}
	
	.detail{
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
		width: 65%;
		margin-left: 22px;
	}
	
	.detail-name{
		font-size: 17px;
	}
	
	.detail-message{
		
		font-size: 14px;
		color: #666;
	}
	
	.list-time{
		margin-left: 30upx;
		font-size: 20upx;
		color: #ababab;
	}


</style>